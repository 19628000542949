@import url(https://fonts.googleapis.com/css2?family=Livvic:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&amp;display=swap);
body,
p {
  color: #444;
  font-weight: 400;
}
.pt-100,
.ptb-100 {
  padding-top: 100px;
}
.banner-area,
.banner-area-two::before,
.banner-four-area::before,
.banner-item {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.go-top,
.top-head-left .top-contact h3 a,
a,
a:focus,
a:hover {
  text-decoration: none;
}
body {
  font-size: 15px;
  line-height: 1.6;
  font-family: Livvic, sans-serif;
}
a {
  display: inline-block;
  transition: 0.5s;
}
button {
  margin: 0;
  padding: 0;
  outline: 0;
}
button:focus {
  outline: 0;
  border: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #252525;
}
h3 {
  font-size: 22px;
}
.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.error-area .error-content img,
img {
  max-width: 100%;
}
.ptb-100 {
  padding-bottom: 100px;
}
.pt-80 {
  padding-top: 80px;
}
.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-20 {
  padding-top: 20px;
}
.about-content .about-content-card,
.mt-30 {
  margin-top: 30px;
}
.ml-25,
.side-bar-area {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.margin-auto,
.work-process-left .default-btn,
.work-process-right .default-btn {
  margin: 0 auto;
}
.border-radius-50 {
  border-radius: 50px;
}
.blog-card .blog-img img,
.blog-item .blog-img img,
.blog-item .blog-img3 img,
.border-radius-5,
.build-item a img,
.case-study-item a img,
.services-item a img {
  border-radius: 5px;
}
.box-shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}
.section-title span {
  margin-bottom: 8px;
  font-weight: 600;
  display: block;
}
.section-title h2 {
  font-size: 35px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.2;
  color: #252525;
  margin-bottom: 0;
}
.section-title p {
  padding-top: 10px;
  margin-bottom: 0;
  max-width: 530px;
  color: #444;
}
.about-content .about-list li:hover i,
.banner-bottom-card:hover h3,
.banner-four-content .banner-btn .play-btn:hover span,
.blog-article .comments-wrap .comment-list li a:hover,
.blog-card .content ul li a:hover,
.blog-card:hover .content h3 a,
.blog-item .content ul li a:hover i,
.build-item:hover .content h3 a,
.call-contact .call-btn:hover,
.case-article .case-article-content .case-article-list li:hover i,
.contact-form .agree-label label a:hover,
.contact-form .form-group.checkbox-option p a:hover,
.footer-widget .footer-blog li h3 a:hover,
.footer-widget .footer-blog li:hover h3 a,
.footer-widget .footer-contact-list li .content a:hover,
.footer-widget .footer-list li a:hover,
.inner-banner .inner-title ul li a:hover,
.service-article .service-article-content .service-article-list li:hover i,
.services-item:hover .content h3 a,
.sp-color1,
.top-head-left .top-contact h3 a:hover,
.top-head-left .top-contact:hover h3 a,
.top-header-right .top-header-social ul li a:hover,
.user-form .contact-form .account-desc a:hover {
  color: #ffc221;
}
.about-content .about-list li:hover,
.blog-card .content ul li a:hover i,
.blog-item .content ul li a:hover,
.blog-item:hover .content h3 a,
.blog-style-card:hover .content h3 a,
.build-item .content ul li:hover a,
.case-article .case-article-content .case-article-list li:hover,
.case-study-item .content h3:hover a,
.case-study-item .content ul li:hover a,
.case-study-item2 h3:hover a,
.case-study-item2:hover .content h3 a,
.case-study-item:hover .content h3 a,
.contact-card span a:hover,
.contact-form .agree-label label a,
.contact-form .form-group label span,
.contact-form .form-group.checkbox-option p a,
.contact-info ul li .content a:hover,
.footer-widget .footer-call-content span a:hover,
.main-nav .nav-side .nav-side-item .search-box:hover i,
.main-nav nav .navbar-nav .nav-item .active i,
.main-nav nav .navbar-nav .nav-item a :focus i,
.main-nav nav .navbar-nav .nav-item a.active i,
.main-nav nav .navbar-nav .nav-item a:hover i,
.main-nav nav .navbar-nav .nav-item:hover a i,
.security-card:hover h3 a,
.service-article .service-article-content .service-article-list li:hover,
.services-item .content span,
.side-bar-widget .side-bar-categories ul li a:hover,
.side-bar-widget .side-bar-categories ul li a:hover span,
.side-bar-widget .widget-popular-post .item .info .title-text a:hover,
.side-nav-responsive
  .side-nav-inner
  .side-nav
  .side-nav-item
  .search-box:hover
  i,
.sp-color2,
.technology-card-color i,
.technology-card-color2 i,
.user-form .contact-form .account-desc a {
  color: #0071dc;
}
.default-btn {
  display: inline-block;
  padding: 12px 32px;
  color: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.default-btn i {
  font-size: 20px;
  position: relative;
  top: 4px;
  display: none;
}
.default-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  height: 100%;
  left: -36%;
  top: 0;
  transform: skew(50deg);
  transition-duration: 0.6s;
  transform-origin: top left;
  width: 0;
}
.default-btn:hover,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li.active {
  color: #fff !important;
}
.default-btn:hover:before {
  height: 100%;
  width: 135%;
  background-color: #252525;
}
.blog-article .blog-article-share .blog-tag ul li:hover a,
.btn-bg-one,
.go-top:hover,
.side-nav-responsive .dot-menu:hover .circle-inner .circle {
  background-color: #ffc221;
}
.btn-bg-two,
.main-nav
  .nav-side
  .nav-side-item
  .search-side-widget
  .search-side-form
  button:hover {
  background-color: #0071dc;
}
.top-header {
  padding: 7px 0 10px;
}
.about-bg,
.choose-area,
.clients-content-color,
.clients-content-color::before,
.footer-bg,
.top-header-bg {
  background-color: #070b3b;
}
.top-left-side .top-header-text {
  display: inline-block;
  position: relative;
  margin-left: 30px;
}
.top-left-side .top-header-text::before {
  content: "";
  position: absolute;
  left: -20px;
  width: 1px;
  height: 22px;
  background-color: #fff;
  top: 1px;
}
.top-left-side .top-header-text p {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 0;
}
.top-left-side .top-header-text p b {
  color: #ffc221;
  font-weight: 500;
  margin-right: 5px;
}
.top-head-left {
  float: left;
}
.top-head-left .top-contact {
  position: relative;
  display: inline-block;
}
.blog-article .blog-article-share .social-icon li a:hover,
.build-item:hover .more-btn,
.case-study-item .content .more-btn:hover,
.case-study-item2 .more-btn:hover,
.case-study-item2:hover .content .more-btn,
.case-study-item:hover .content .more-btn,
.footer-widget .footer-call-content:hover i,
.footer-widget .social-link ul li a:hover,
.pagination-area .page-numbers.current,
.pagination-area .page-numbers:hover,
.security-card:hover i,
.top-head-left .top-contact:hover i,
.top-header-right .top-header-social-bg ul li a:hover {
  background-color: #0071dc;
  color: #fff;
}
.top-head-left .top-contact h3 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 0;
  font-weight: 500;
}
.top-head-left .top-contact h3 a {
  color: #fff;
  margin-bottom: 0;
}
.banner-four-content .banner-btn .default-btn,
.top-header-right .top-header-social {
  display: inline-block;
}
.blog-article .blog-article-share .blog-tag ul,
.blog-article .blog-article-title ul,
.blog-article .comments-wrap .comment-list,
.blog-card .content ul,
.blog-style-card .content ul,
.build-item .content ul,
.case-study-item .content ul,
.contact-info ul,
.footer-widget .footer-blog,
.footer-widget .footer-list,
.top-header-right .top-header-social ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.top-header-right .top-header-social ul li {
  display: inline-block;
  top: 1px;
  position: relative;
}
.top-header-right .top-header-social ul li a {
  width: 30px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  background-color: transparent;
  color: #fff;
  border-radius: 50px;
  font-size: 18px;
}
.top-header-right .language-list {
  top: -6px;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
  margin-left: -20px;
  z-index: 9999;
}
.top-header-right .language-list .language-list-item {
  height: 0;
  padding: 0 30px;
  border: none;
  color: #fff;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px 0 0 5px;
}
.main-nav
  .nav-side
  .nav-side-item
  .search-side-widget
  .search-side-form
  .form-control:focus,
.search-widget .search-form .form-control:focus,
.top-header-right .language-list .language-list-item:focus {
  box-shadow: none;
  outline: 0;
}
.top-header-right .language-list .nice-select {
  height: 0;
  width: 125px;
  line-height: 0;
  font-size: 15px;
  margin-bottom: 0;
  border-radius: 5px 0 0 5px;
  background-color: transparent;
  color: #fff;
  z-index: 9999;
  text-align: right !important;
}
.top-header-right .language-list .nice-select .list {
  background-color: #fff;
  box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
  border-radius: 0;
  margin-top: 20px;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.top-header-right .language-list .nice-select .list .option {
  transition: 0.6s;
  color: #252525;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
  width: 180px;
  border-bottom: 1px solid #e1e1e1;
}
.top-header-right .language-list .nice-select .list .option:hover {
  background-color: transparent !important;
  color: #0071dc;
}
.top-header-right .language-list .nice-select .list .option .selected {
  background-color: transparent !important;
  font-weight: 500;
  color: #0071dc !important;
}
.top-header-right .language-list .nice-select::after {
  content: "\ea29";
  height: 8px;
  width: 8px;
  border: none;
  top: 0;
  margin-bottom: 0;
  right: 10px;
  background-color: transparent;
  font-family: boxicons !important;
  color: #fff;
  font-size: 18px;
  transform-origin: none;
  transform: none;
  margin-top: 0;
}
.main-nav {
  background-color: #fff;
  top: 0;
  position: inherit;
  left: 0;
  padding: 0;
  width: 100%;
  height: auto;
}
.main-nav .navbar {
  padding-left: 0;
  padding-right: 0;
}
.build-item .content ul li:last-child::before,
.case-study-item .content ul li:last-child::before,
.coming-soon-area .coming-soon-content #timer div:last-child::before,
.counter-shape,
.main-nav .navbar .navbar-brand .logo-two,
.side-nav-responsive {
  display: none;
}
.main-nav nav .navbar-nav .nav-item .active,
.main-nav nav .navbar-nav .nav-item a :focus,
.main-nav nav .navbar-nav .nav-item a.active,
.main-nav nav .navbar-nav .nav-item a:hover,
.main-nav nav .navbar-nav .nav-item:hover a {
  color: #0071dc !important;
}
.main-nav nav .navbar-nav .nav-item .active::before,
.main-nav nav .navbar-nav .nav-item a :focus::before,
.main-nav nav .navbar-nav .nav-item a.active::before,
.main-nav nav .navbar-nav .nav-item a:hover::before,
.main-nav nav .navbar-nav .nav-item:hover a::before {
  opacity: 1;
  width: 100%;
}
.main-nav nav .navbar-nav .nav-item a {
  text-transform: capitalize;
  color: #252525;
  font-weight: 500;
  margin-left: 12px;
  margin-right: 12px;
  position: relative;
}
.main-nav nav .navbar-nav .nav-item a span {
  font-size: 10px;
  color: red;
  margin-left: 2px;
  top: -7px;
  position: relative;
}
.main-nav nav .navbar-nav .nav-item a::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 1px;
  left: 0;
  right: 0;
  background-color: #0071dc;
  opacity: 0;
  transition: 0.7s;
}
.main-nav nav .navbar-nav .nav-item a i {
  line-height: 0;
  position: relative;
  top: 3px;
  font-size: 18px;
  color: #444;
}
.main-nav nav .navbar-nav .nav-item:hover .dropdown-menu {
  transform: scale(1);
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu {
  z-index: 5;
  border: none;
  padding: 0;
  border-radius: 0;
  background-color: #fff !important;
  transform: scale(0);
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li {
  border-bottom: 1px solid #e1e1e1;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:last-child,
.side-bar-widget .side-bar-categories ul li:last-child {
  border-bottom: none;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: capitalize;
  color: #252525 !important;
  position: relative;
  z-index: 1;
  transition: 0.7s;
  font-weight: 600;
  padding: 10px 25px;
  font-size: 15px;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a i {
  float: right;
  top: 12px;
  position: relative;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: #0071dc;
  transition: 0.9s;
  z-index: -1;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
  border-radius: 0;
  color: #fff !important;
  padding-left: 35px;
}
.about-content-2 .section-title h2,
.about-content-2 .section-title p,
.about-play .about-play-content span,
.banner-four-content .banner-btn .play-btn:hover h3,
.build-content .section-title h2,
.build-content .section-title span,
.build-play-img .play-area-content span,
.choose-content .section-title p,
.clients-content-color .content h3,
.clients-content-color .content span,
.clients-content-color p,
.coming-soon-area .coming-soon-content ul li a,
.inner-banner .inner-title ul li a,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a .active i,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a :focus i,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active i,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover i,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li.active i,
.services-card .learn-btn:hover,
.services-card .learn-btn:hover i,
.services-card:hover .learn-btn,
.services-card:hover .learn-btn i,
.services-card:hover h3 a,
.services-card:hover i,
.services-card:hover p,
.team-card .content span,
.team-item .content span,
.work-process-left .section-title h2,
.work-process-right .section-title h2 {
  color: #fff;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a .active::before,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a :focus::before,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active::before,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover::before,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li.active::before {
  width: 100%;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a .active,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a :focus,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover {
  color: #fff !important;
  border-radius: 0;
  padding-left: 35px;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: 100%;
  margin-top: 18px !important;
  position: absolute;
  transform: scaleX(0);
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  transform: scaleX(1);
}
.about-img,
.banner-slider-area,
.blog-card .blog-img,
.blog-item .blog-img,
.blog-item .blog-img2,
.blog-item .blog-img3,
.blog-style-card .blog-style-img,
.brand-area,
.contact-form .form-group.checkbox-option,
.counter-area,
.main-nav .nav-side.nav-side,
.search-overlay .search-form form,
.work-process-area-two {
  position: relative;
}
.main-nav .nav-side .nav-side-item {
  margin-right: 20px;
  display: inline-block;
}
.blog-article .blog-article-share .blog-tag ul li:last-child,
.blog-article .blog-article-title ul li:last-child,
.blog-card .content ul li a:last-child,
.blog-item .content ul li a:last-child,
.blog-style-card .content ul li:last-child,
.coming-soon-area .coming-soon-content #timer div:last-child,
.footer-widget .social-link ul li:last-child,
.main-nav .nav-side .nav-side-item:last-child,
.top-header-right .top-header-social-bg ul li:last-child {
  margin-right: 0;
}
.main-nav .nav-side .nav-side-item .search-box {
  position: relative;
  top: -5px;
}
.main-nav .nav-side .nav-side-item .search-box i {
  cursor: pointer;
  font-size: 24px;
  color: #252525;
  position: relative;
  display: inline-block;
  top: 0;
}
.main-nav .nav-side .nav-side-item .search-side-widget .search-side-form {
  position: relative;
  top: -10px;
}
.main-nav
  .nav-side
  .nav-side-item
  .search-side-widget
  .search-side-form
  .form-control {
  height: 45px;
  background-color: #f8f8f8;
  padding: 10px 20px;
  width: 100%;
  border-radius: 50px;
  border: none;
}
.main-nav
  .nav-side
  .nav-side-item
  .search-side-widget
  .search-side-form
  button {
  position: absolute;
  top: 3px;
  right: 3px;
  height: 40px;
  width: 40px;
  background-color: #ffc221;
  transition: 0.5s;
  border: none;
  outline: 0;
  border-radius: 50px;
}
.main-nav
  .nav-side
  .nav-side-item
  .search-side-widget
  .search-side-form
  button
  i {
  color: #fff;
  position: relative;
  top: 1.9px;
}
.main-nav .nav-side .nav-side-item .get-btn,
.side-nav-responsive .side-nav-inner .side-nav .side-nav-item .get-btn {
  display: inline-block;
  position: relative;
  top: 5px;
}
.main-nav .nav-side .nav-side-item .get-btn .default-btn {
  padding: 9px 25px;
}
.nav-side-mt {
  margin-top: 6px;
}
.side-nav-responsive .dot-menu {
  padding: 0 10px;
  height: 30px;
  cursor: pointer;
  z-index: 999;
  position: absolute;
  right: 60px;
  top: 20px;
}
.side-nav-responsive .dot-menu .circle-inner {
  display: flex;
  align-items: center;
  height: 30px;
}
.side-nav-responsive .dot-menu .circle-inner .in-circle {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 2px;
  transition: 0.7s;
  background-color: #0071dc;
}
.side-nav-responsive .container-max {
  position: relative;
  display: flex;
}
.side-nav-responsive .container-max .container {
  position: absolute;
  top: 70px;
  right: 0;
  max-width: 220px;
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  transition: 0.7s;
  transform: scaleX(0);
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
}
.side-nav-responsive .container-max .container.active {
  opacity: 1;
  visibility: visible;
  transform: scaleX(1);
}
.side-nav-responsive .side-nav-inner {
  padding: 12px 10px 10px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
  background-color: #fff;
}
.side-nav-responsive .side-nav-inner .side-nav {
  background-color: #0071dc;
  padding: 10px;
}
.side-nav-responsive .side-nav-inner .side-nav .side-nav-item .search-box {
  position: relative;
  display: inline-block;
  top: -10px;
  margin-right: 5px;
}
.side-nav-responsive .side-nav-inner .side-nav .side-nav-item .search-box i {
  cursor: pointer;
  font-size: 24px;
  color: #fff;
  position: relative;
  display: inline-block;
  top: 5px;
}
.side-nav-responsive
  .side-nav-inner
  .side-nav
  .side-nav-item
  .get-btn
  .default-btn {
  padding: 7px 15px;
  background-color: #fff;
  color: #252525;
}
.sticky-nav {
  top: 0;
  position: fixed;
  transition: 0.7s;
  width: 100% !important;
  z-index: 999;
}
.sticky-nav .main-nav {
  top: 0;
  border: none;
  position: fixed;
  z-index: 999;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}
.about-img .sub-content,
.banner-sub-item,
.services-item .content {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}
.search-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  overflow: hidden;
}
.search-overlay .search-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transform: translateX(100%);
}
.search-overlay .search-layer:first-child {
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
}
.search-overlay .search-layer:nth-child(2) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out 0.3s;
}
.search-overlay .search-layer:nth-child(3) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.9s ease-in-out 0.6s;
}
.search-overlay .search-close {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 50px;
  z-index: 2;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  transition: 0.9s ease-in-out 1.5s;
  opacity: 0;
  visibility: hidden;
}
.search-overlay .search-close .search-close-line {
  width: 100%;
  height: 3px;
  float: left;
  margin-bottom: 5px;
  background-color: #fff;
  transition: 0.5s;
}
.search-overlay .search-close .search-close-line:first-child {
  transform: rotate(45deg);
}
.search-overlay .search-close .search-close-line:nth-child(2) {
  margin-top: -7px;
  transform: rotate(-45deg);
}
.search-overlay .search-close:hover .search-close-line {
  background: #0071dc;
  transform: rotate(180deg);
}
.search-overlay .search-form {
  transition: 0.9s ease-in-out 1.4s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
  max-width: 500px;
  width: 500px;
  padding: 0 15px;
}
.search-overlay .search-form form .input-search {
  display: block;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 30px;
  color: #252525;
  padding: 3px 0 0 25px;
}
.search-overlay .search-form form .input-search::-moz-placeholder {
  -moz-transition: 0.7s;
  transition: 0.7s;
  letter-spacing: 0.5px;
  color: #252525;
}
.search-overlay .search-form form .input-search::placeholder {
  transition: 0.7s;
  letter-spacing: 0.5px;
  color: #252525;
}
.search-overlay .search-form form .input-search:focus,
.search-overlay .search-form form:hover {
  border: none;
  outline: 0;
}
.search-overlay .search-form form .input-search:focus::-moz-placeholder {
  color: transparent;
}
.search-overlay .search-form form .input-search:focus::placeholder {
  color: transparent;
}
.search-overlay .search-form form button {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 50px;
  color: #fff;
  height: 50px;
  border-radius: 50%;
  background-color: #0071dc;
  transition: 0.7s;
  border: none;
  font-size: 20px;
  line-height: 55px;
}
.banner-four-content .banner-btn .play-btn:hover i,
.search-overlay .search-form form button:hover,
.technology-play-area .play-btn:hover i {
  background-color: #ffc221;
  color: #fff;
}
.search-overlay.search-overlay-active.search-overlay,
.search-overlay.search-overlay-active.search-overlay .search-close,
.search-overlay.search-overlay-active.search-overlay .search-form,
.side-bar-widget .blog-gallery li a:hover::after {
  opacity: 1;
  visibility: visible;
}
.search-overlay.search-overlay-active.search-overlay .search-layer {
  transform: translateX(0);
}
.banner-slider-area .owl-dots {
  margin-top: 0 !important;
  position: absolute;
  display: grid;
  right: 7%;
  top: 50%;
  transform: translateY(-50%);
  margin-bottom: 10px;
}
.banner-area-two::before,
.banner-area::before,
.banner-item::before {
  top: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
  right: 0;
  left: 0;
  content: "";
}
.banner-slider-area .owl-dots .owl-dot span {
  background-color: rgba(251, 251, 251, 0.2470588235) !important;
  transition: 0.7s;
  margin: 7px;
  width: 40px !important;
  height: 40px !important;
  border: 10px solid rgba(251, 251, 251, 0.2470588235);
}
.banner-slider-area .owl-dots .owl-dot.active span,
.banner-slider-area .owl-dots .owl-dot:hover span {
  background-color: #fff !important;
  width: 40px !important;
  height: 40px !important;
  border: 13px solid #ffc221;
}
.banner-item {
  position: relative;
  z-index: 1;
}
.banner-item::before {
  background-color: #070b3b;
  opacity: 0.7;
}
.banner-item-content {
  padding-top: 130px;
  padding-bottom: 120px;
}
.banner-item-content span {
  font-size: 15px;
  font-weight: 500;
  color: #0071dc;
  padding: 10px 25px;
  background-color: #fff;
  border-radius: 50px;
  display: inline-block;
}
.banner-item-content h1 {
  margin-top: 25px;
  font-size: 70px;
  color: #fff;
  font-weight: 700;
  max-width: 780px;
  margin-bottom: 20px;
}
.banner-item-content p,
.banner-six-content p {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  max-width: 660px;
  margin-bottom: 35px;
}
.item-bg1 {
}
.item-bg2 {
}
.item-bg3 {
}
.banner-area {
  position: relative;
  z-index: 1;
}
.banner-area::before {
  background-image: linear-gradient(
    to right,
    rgba(37, 37, 37, 0.7490196078),
    rgba(37, 37, 37, 0)
  );
}
.banner-item-ptb {
  padding-top: 140px;
  padding-bottom: 160px;
}
.banner-item-ptb h1,
.banner-seven-slide.owl-theme .owl-nav,
.clients-area .owl-nav {
  margin-top: 0;
}
.banner-area-two {
  position: relative;
  z-index: 1;
  background-color: #f8f8f8;
}
.banner-area-two::before {
}
.banner-content {
  position: relative;
  margin-top: -50px;
}
.banner-content h1 {
  font-size: 60px;
  color: #252525;
  font-weight: 600;
  max-width: 680px;
  line-height: 1.2;
  margin-bottom: 20px;
}
.banner-content p {
  font-size: 17px;
  color: #252525;
  font-weight: 500;
  max-width: 500px;
  margin-bottom: 35px;
}
.banner-img {
  position: relative;
  padding-top: 70px;
}
.banner-img .banner-img-shape {
  position: absolute;
  bottom: 100px;
  right: 0;
  animation: 9s linear infinite moveBounce;
}
.banner-sub-item {
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  padding: 15px 10px 15px 160px;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}
.banner-sub-item img {
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 125px;
  border-radius: 15px 0 0 15px;
}
.banner-sub-item .content,
.blog-article .article-content {
  padding-bottom: 10px;
}
.about-img .sub-content h3,
.banner-sub-item .content h3 {
  font-size: 45px;
  color: #ffc221;
  font-weight: 700;
  margin-bottom: 0;
}
.about-img .sub-content span,
.banner-sub-item .content span {
  color: #252525;
  font-size: 18px;
  font-weight: 600;
}
.banner-sub-slider {
  position: relative;
  margin-top: -80px;
  padding-bottom: 50px;
}
.banner-four-area {
  background-color: #070b3b;
  z-index: 1;
  position: relative;
}
.banner-four-area::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70%;

  opacity: 0.8;
}
.services-card::after,
.services-card::before {
  opacity: 0.1;
  position: absolute;
  background-color: #0071dc;
}
.banner-four-content {
  max-width: 620px;
  margin-left: auto;
  margin-right: -50px;
  position: relative;
  z-index: 2;
}
.banner-four-content span {
  font-size: 14px;
  font-weight: 400;
  color: #ffc221;
  border-radius: 50px;
  display: inline-block;
  margin-bottom: 15px;
}
.banner-four-content h1 {
  font-size: 50px;
  color: #fff;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 20px;
}
.banner-four-content p {
  font-size: 17px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 30px;
}
.banner-four-content .banner-btn .play-btn {
  padding-left: 65px;
  position: relative;
  z-index: 1;
  top: -3px;
}
.banner-four-content .banner-btn .play-btn i {
  position: absolute;
  top: -3px;
  left: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  text-align: center;
  background-color: #fff;
  color: #ffc221;
  border-radius: 50px;
  transition: 0.7s;
}
.banner-four-content .banner-btn .play-btn i::after,
.technology-play-area .play-btn i::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0.5px solid #fff;
  border-radius: 50px;
  animation: 2s ease-in-out infinite ripple;
}
.banner-four-content .banner-btn .play-btn h3 {
  margin-bottom: 5px;
  font-size: 16px;
  color: #ffc221;
  transition: 0.7s;
}
.banner-four-content .banner-btn .play-btn span {
  font-size: 14px;
  color: #fff;
  transition: 0.7s;
  margin-bottom: 0;
}
.banner-four-content .banner-btn .play-btn:hover i::after,
.technology-play-area .play-btn:hover i::after {
  border-color: #ffc221;
}
.banner-bottom-card,
.security-card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border-bottom: 3px solid #0071dc;
}
.banner-four-img {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}
.banner-five-area {
  background-color: #d5e5f4;
  position: relative;
  padding: 50px 0;
}
.banner-five-content {
  max-width: 560px;
  margin-left: auto;
}
.banner-five-content span {
  font-size: 14px;
  font-weight: 600;
  color: #0071dc;
  border-radius: 50px;
  display: inline-block;
  margin-bottom: 15px;
}
.banner-five-content h1 {
  font-size: 52px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 20px;
}
.banner-bottom-card i,
.security-card i {
  line-height: 80px;
  background-color: #e5f3ff;
}
.banner-five-content h1 b {
  font-weight: 600;
  color: #0071dc;
}
.banner-five-content p {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 30px;
}
.banner-bottom-card i,
.banner-seven-slide.owl-theme .owl-nav [class*="owl-"] {
  font-size: 40px;
  color: #252525;
  text-align: center;
  position: absolute;
  display: inline-block;
}
.banner-five-img {
  animation: 9s ease-in-out infinite moveBounce;
}
.banner-bottom-area {
  position: relative;
  margin-top: -75px;
}
.banner-bottom-card {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 30px 20px 30px 135px;
  border-radius: 15px;
  margin-bottom: 30px;
  transition: 0.5s;
}
.banner-bottom-card i {
  top: 30px;
  left: 30px;
  width: 80px;
  height: 80px;
  border-radius: 50px;
}
.banner-bottom-card h3 {
  color: #252525;
  margin-bottom: 10px;
  transition: 0.5s;
}
.banner-bottom-card p,
.clients-slider-content span {
  margin-bottom: 0;
  font-weight: 500;
}
.banner-bottom-card:hover,
.blog-card:hover,
.blog-item:hover {
  transform: translateY(-10px);
}
.banner-seven-slide.owl-theme .owl-nav [class*="owl-"] {
  background-color: #fff;
  width: 50px;
  height: 50px;
  line-height: 58px;
  border-radius: 50%;
  left: 160px;
  top: 52%;
  transform: translateY(-50%);
  transition: 0.5s;
}
.banner-seven-slide.owl-theme .owl-nav [class*="owl-"] span {
  position: relative;
  top: -9px;
}
.banner-seven-slide.owl-theme .owl-nav [class*="owl-"].owl-next {
  top: 45%;
  left: 160px;
  right: auto;
}
.banner-seven-slide.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: #070b3b;
  color: #fff;
}
.banner-six.banner-eight {
  background-image: none;
}
.banner-six.banner-eight .background-video {
  position: absolute;
  top: -85px;
  left: 0;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  z-index: -2;
}
.inner-banner,
.technology-area-three {
  position: relative;
  z-index: 1;
  background-color: #070b3b;
}
.inner-banner .inner-title {
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
  z-index: 1;
}
.inner-banner .inner-title h3 {
  font-size: 45px;
  color: #fff;
  font-weight: 600;
  position: relative;
}
.inner-banner .inner-title ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}
.inner-banner .inner-title ul li {
  font-size: 18px;
  color: #fff;
  display: inline-block;
}
.inner-banner .inner-title ul li i {
  color: #fff;
  position: relative;
  top: 3px;
  margin: 0 3px;
}
.inner-banner .inner-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
}
.about-content .about-content-card i {
  font-size: 60px;
  color: #0071dc;
  line-height: 1;
}
.about-content .about-content-card h3 {
  margin-top: 15px;
  margin-bottom: 12px;
}
.about-content .about-content-card p,
.about-content .about-list li:last-child,
.blog-article .comments-wrap .comment-list li:last-child,
.case-article .case-article-content .case-article-list li:last-child,
.case-study-item2 p,
.clients-content .content span,
.clients-content p,
.faq-content .faq-accordion .accordion .accordion-item:last-child,
.footer-widget .footer-blog li:last-child,
.footer-widget .footer-list li:last-child,
.security-card p,
.service-article .service-article-content .service-article-list li:last-child,
.service-article .work-process-card p,
.work-process-card p,
.work-process-card-three p,
.work-process-card-two p {
  margin-bottom: 0;
}
.about-content .about-list {
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
}
.about-content .about-list li {
  display: block;
  color: #252525;
  margin-bottom: 10px;
  font-weight: 500;
  transition: 0.5s;
  padding-left: 35px;
  position: relative;
  font-size: 15px;
}
.about-content .about-list li i,
.service-article .service-article-content .service-article-list li i {
  font-size: 26px;
  color: #0071dc;
  position: absolute;
  left: 0;
  top: -2px;
  margin-right: 5px;
  transition: 0.5s;
}
.about-content .about-content-text {
  margin-top: 20px;
  margin-bottom: 0;
}
.about-img::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 30%;
  height: 40%;
  background-color: transparent;
  border: 3px solid #ffc221;
  bottom: -10px;
  right: -40px;
  border-radius: 35px;
}
.about-img img,
.about-img-2 img,
.blog-article .blog-article-img img,
.blog-style-card .blog-style-img img,
.case-article .case-article-another .case-article-another-img img,
.case-article .case-article-img img,
.choose-img img,
.service-article .service-article-another .service-article-another-img img,
.service-article .service-article-img img {
  border-radius: 15px;
}
.about-play,
.about-play img,
.build-play-img img {
  border-radius: 30px;
}
.about-img .sub-content {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  background-color: #fff;
  border-radius: 15px;
  padding: 15px 10px 23px 160px;
  max-width: 340px;
  margin-left: auto;
  margin-right: auto;
}
.about-play .about-play-content,
.about-play .about-play-content::before {
  border-bottom-left-radius: 30px;
  border-top-right-radius: 70%;
  position: absolute;
  bottom: 0;
}
.about-img .sub-content img {
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 123px;
  border-radius: 15px 0 0 15px;
}
.about-play {
  position: relative;
}
.about-play .about-play-content .play-on-area .play-on,
.security-card i {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
}
.about-play .about-play-content {
  z-index: 1;
  left: 0;
  max-width: 470px;
  background-color: rgba(37, 37, 37, 0.2196078431);
  padding: 100px 40px 70px 25px;
}
.about-play .about-play-content::before {
  content: "";
  z-index: -1;
  right: 30px;
  width: 91.7%;
  height: 90.1%;
  background-color: #0071dc;
}
.about-play .about-play-content h2,
.build-play-img .play-area-content h2 {
  color: #fff;
  font-size: 35px;
  max-width: 300px;
  margin-bottom: 0;
}
.about-content-2,
.about-content-3,
.about-img-2,
.about-img-4,
.blog-article,
.blog-article .comments-wrap,
.blog-style-card,
.case-article .case-work-process p,
.choose-content,
.choose-img,
.faq-content,
.faq-content .section-title,
.footer-widget,
.service-article .service-work-process p,
.services-left,
.single-content,
.talk-content .section-title,
.technology-area-four .section-title,
.technology-area-three .section-title,
.terms-conditions-img,
.user-img,
.work-process-left .section-title,
.work-process-right .section-title {
  margin-bottom: 30px;
}
.about-play .about-play-content .play-on-area {
  position: absolute;
  z-index: 1;
  bottom: 175px;
  left: 200px;
}
.about-play .about-play-content .play-on-area .play-on {
  color: #0071dc;
  background-color: #fff;
  font-size: 55px;
}
.about-play .about-play-content .play-on-area .play-on i {
  padding-left: 3px;
}
.about-play .about-play-content .play-on-area .play-on:hover,
.play-area .play-on:hover,
.side-bar-widget .side-bar-widget-tag li:hover a {
  color: #fff;
  background-color: #ffc221;
}
.about-content-2 .about-card,
.choose-content .choose-content-card {
  margin-top: 15px;
}
.about-content-2 .about-card .content {
  padding-top: 20px;
  position: relative;
  padding-left: 70px;
  margin-bottom: 40px;
}
.about-content-2 .about-card .content i {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 55px;
  color: #0071dc;
  line-height: 1;
}
.about-content-2 .about-card .content h3 {
  margin-bottom: 10px;
  color: #fff;
}
.about-content-2 .about-card p {
  margin-top: 25px;
  margin-bottom: 0;
  color: #fff;
}
.about-bg2,
.build-area-two,
.services-area-three,
.technology-area-four,
.technology-area-two {
  background-color: #f8f8f8;
}
.about-img-4 {
  max-width: 750px;
  margin-left: auto;
}
.about-content-3 {
  max-width: 560px;
  margin-right: auto;
}
.about-content-3 .section-title,
.blog-article .article-content p,
.blog-article .blog-article-title,
.case-article .case-article-another p,
.case-article .case-article-content p,
.service-article .service-article-another p,
.service-article .service-article-content p,
.services-left .section-title,
.skill-bar,
.work-process-rightside .section-title {
  margin-bottom: 20px;
}
.about-content-3 h3 {
  font-size: 20px;
  margin-bottom: 20px;
}
.all-skill-bar {
  margin-right: 30px;
  margin-bottom: 30px;
}
.skill-bar .progress-title-holder {
  position: relative;
  margin-bottom: 15px;
}
.skill-bar .progress-title {
  font-size: 16px;
  font-weight: 600;
  color: #252525;
}
.skill-bar .progress-number-wrapper {
  width: 100%;
  z-index: 10;
  font-size: 11px;
  line-height: 24px;
  height: 24px;
  letter-spacing: 0;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  color: #fbede9;
}
.skill-bar .progress-number-mark {
  margin-bottom: 4px;
  border-radius: 3px;
  color: #fff;
  padding: 0 8px;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: 600;
  width: 45px;
  height: 26px;
  text-align: center;
  line-height: 28px;
  background: #070b3b;
}
.brand-area-two::before,
.brand-area::before,
.build-area::after,
.call-us-area::before,
.case-play-btn::before,
.clients-area-three::before,
.clients-area::before,
.contact-info::before,
.play-btn-area::before,
.services-area-two::before,
.talk-area,
.talk-area-two,
.technology-area,
.technology-area-five::before,
.technology-area-three::before,
.technology-play-area,
.work-process-left::before,
.work-process-right::before {
  background-position: center center;
  background-size: cover;
}
.skill-bar .progress-content-outter {
  height: 10px;
  background-color: #bddfff;
  border-radius: 5px;
}
.skill-bar .progress-content {
  height: 10px;
  background-color: #0071dc;
  width: 0%;
  border-radius: 5px;
}
.security-area .section-title h2 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.security-card {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 30px;
}
.services-card-color-bg,
.services-style-bg {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
}
.security-card i {
  font-size: 45px;
  color: #252525;
  margin-bottom: 12px;
  transition: 0.5s;
}
.security-card h3 {
  margin-bottom: 10px;
}
.security-card h3 a {
  color: #252525;
  transition: 0.5s;
  display: block;
}
.services-area {
  background-color: #070b3b;
  position: relative;
}
.services-area .section-title h2,
.services-area-two .section-title h2 {
  max-width: 500px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
}
.services-area .section-title p {
  color: #fff;
  max-width: 760px;
}
.services-card {
  padding: 30px 20px 25px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.services-card::before {
  content: "";
  z-index: -1;
  bottom: 0;
  right: 0;
  width: 90px;
  height: 90px;
  border-radius: 272px 0 0;
  transition: 0.7s;
}
.services-card::after {
  content: "";
  z-index: -1;
  bottom: 0;
  right: 0;
  width: 75px;
  height: 75px;
  border-radius: 170px 0 0;
  transition: 0.7s;
}
.services-card i {
  color: #0071dc;
  font-size: 60px;
  line-height: 1;
  transition: 0.7s;
}
.services-card h3 {
  margin-top: 20px;
  margin-bottom: 12px;
  transition: 0.7s;
}
.services-card h3 a {
  display: block;
  color: #252525;
  transition: 0.7s;
}
.services-card p {
  margin-bottom: 12px;
  transition: 0.7s;
}
.services-card .learn-btn {
  color: #252525;
  font-size: 14px;
  font-weight: 600;
  transition: 0.7s;
}
.services-card .learn-btn i {
  font-size: 20px;
  position: relative;
  top: 5px;
  color: #252525;
  transition: 0.7s;
}
.services-card:hover::before {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  opacity: 1;
}
.services-card-color-bg {
  padding: 40px 30px 35px;
  border-radius: 15px;
}
.services-left .section-title p {
  padding-top: 17px;
}
.service-shape {
  position: absolute;
  top: 0;
  right: 0;
}
.service-shape img {
  max-width: 400px;
}
.call-us-area,
.services-area-two {
  background-color: #070b3b;
  position: relative;
  z-index: 1;
}
.services-area-two::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.services-item {
  position: relative;
  margin-bottom: 80px;
  transition: 0.7s;
}
.services-item:hover {
  transform: translateY(-5px);
}
.build-item a,
.case-study-item a,
.faq-content .faq-accordion .accordion .accordion-content.show,
.footer-widget .footer-blog li a,
.services-item a {
  display: block;
}
.services-item .content {
  position: absolute;
  left: 0;
  right: 0;
  width: 82%;
  bottom: -80px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 20px 20px 100px;
  margin-bottom: 30px;
}
.services-item .content i {
  font-size: 60px;
  position: absolute;
  top: 17px;
  left: 20px;
  line-height: 1;
  color: #0071dc;
}
.services-item .content h3 {
  font-size: 20px;
  margin-top: 3px;
  margin-bottom: 0;
}
.blog-card .content h3 a,
.blog-item .content h3 a,
.build-content.build-content-on-color .section-title h2,
.build-content.build-content-on-color .section-title span,
.build-text.build-on-text p,
.case-study-item .content h3 a,
.case-study-item2 h3 a,
.services-item .content h3 a,
.services-widget-area .section-title h2,
.technology-card-color h3 {
  color: #252525;
}
.services-area-three .section-title h2,
.services-style-area .section-title h2 {
  max-width: 390px;
  color: #252525;
  margin-left: auto;
  margin-right: auto;
}
.services-style-area .section-title p {
  max-width: 600px;
}
.case-article .case-article-img,
.choose-content-two .section-title,
.service-article .service-article-img,
.work-process-rightside {
  margin-bottom: 25px;
}
.work-process-card,
.work-process-left {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}
.case-article .case-article-another h2,
.case-article .case-article-content h2,
.case-article .case-work-process h2,
.service-article .service-article-another h2,
.service-article .service-article-content h2,
.service-article .service-work-process h2 {
  font-size: 26px;
  margin-bottom: 15px;
}
.case-article .case-article-content .case-article-list,
.service-article .service-article-content .service-article-list {
  list-style: none;
  margin: 0 0 25px;
  padding: 0;
}
.case-article .case-article-content .case-article-list li,
.service-article .service-article-content .service-article-list li {
  display: block;
  color: #252525;
  margin-bottom: 10px;
  font-weight: 600;
  transition: 0.5s;
  padding-left: 35px;
  position: relative;
}
.case-article .case-article-another .case-article-another-img,
.service-article .service-article-another .service-article-another-img {
  margin-bottom: 25px;
  margin-top: 10px;
}
.work-process-area .section-title h2 {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}
.work-process-left {
  background-color: #070b3b;
  padding: 148px 50px;
  border-radius: 10px;
}
.work-process-card,
.work-process-card-two {
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.work-process-left::before {
  content: "";
  position: absolute;
  z-index: -1;
  opacity: 0.2;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.work-process-card-three::before,
.work-process-card::before {
  opacity: 0.1;
  background-color: #0071dc;
  content: "";
  z-index: -1;
  transition: 0.7s;
}
.work-process-card {
  padding: 30px 20px;
}
.work-process-card::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 55px;
  border-radius: 0 0 0 270px;
}
.work-process-card i {
  font-size: 60px;
  color: #0071dc;
  line-height: 1.2;
}
.work-process-card h3 {
  margin-top: 15px;
  margin-bottom: 10px;
}
.work-process-card .number {
  font-size: 24px;
  color: #0071dc;
  font-weight: 600;
  position: absolute;
  top: 5px;
  right: 15px;
}
.work-shape {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
.work-process-card-two {
  padding: 35px 23px;
  margin-bottom: 30px;
}
.work-process-card-three .number-title,
.work-process-card-two .number-title {
  font-size: 30px;
  color: #0071dc;
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
}
.blog-style-card .content p,
.choose-content-two .choose-content-card h3,
.side-bar-widget .side-bar-categories,
.single-content p,
.work-process-card-three h3,
.work-process-card-two h3 {
  margin-bottom: 15px;
}
.work-process-right {
  background-color: #070b3b;
  padding: 138px 50px;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}
.work-process-right::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.work-process-card-three {
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 35px 23px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}
.work-process-card-three::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 85px;
  height: 75px;
  border-radius: 0 0 0 100%;
}
.work-process-card-three i {
  font-size: 30px;
  color: #0071dc;
  position: absolute;
  top: 7px;
  right: 15px;
}
.choose-content .section-title h2 {
  max-width: 470px;
  color: #fff;
}
.choose-content .choose-content-card .content {
  padding-top: 20px;
  position: relative;
  padding-left: 75px;
}
.choose-content .choose-content-card .content i {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 60px;
  color: #0071dc;
  line-height: 1;
}
.choose-content .choose-content-card .content h3 {
  margin-top: 10px;
  margin-bottom: 30px;
  color: #fff;
}
.choose-content .choose-content-card p {
  margin-top: 5px;
  margin-bottom: 0;
  color: #fff;
}
.choose-content-two .section-title p {
  padding-top: 15px;
}
.choose-content-two .choose-content-card {
  position: relative;
  padding-left: 100px;
  margin-bottom: 25px;
}
.choose-content-two .choose-content-card i {
  position: absolute;
  top: 0;
  left: 0;
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  font-size: 35px;
  color: #fff;
  background-color: #0071dc;
  border-radius: 3px;
}
.choose-content-two .choose-content-card p {
  margin-bottom: 0;
  max-width: 315px;
}
.choose-img-two {
  position: relative;
  z-index: 1;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.choose-img-two::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  width: 90%;
  height: 100%;
  background-color: #070b3b;
}
.build-area::after,
.build-area::before {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  width: 100%;
  height: 50%;
  top: 0;
  content: "";
}
.build-area,
.case-study-area-two {
  position: relative;
  z-index: 1;
}
.build-area::before {
  background-color: #0071dc;
}
.build-area::after {
  background-repeat: no-repeat;
}
.blog-item .blog-img .blog-tag:hover,
.build-area .default-btn,
.team-card .social-link li:hover a,
.team-item .content .social-link li:hover a {
  background-color: #fff;
  color: #0071dc;
}
.build-btn-area,
.top-header-right {
  float: right;
}
.build-play-img {
  position: relative;
  border-radius: 30px;
  margin-bottom: 30px;
}
.build-play-img .play-area-content {
  position: absolute;
  z-index: 1;
  bottom: -1px;
  left: -1px;
  max-width: 470px;
  background-color: rgba(37, 37, 37, 0.2196078431);
  border-bottom-left-radius: 30px;
  border-top-right-radius: 70%;
  padding: 150px 80px 110px 40px;
}
.build-play-img .play-area-content::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 30px;
  right: 30px;
  width: 92.7%;
  height: 92.1%;
  background-color: #0071dc;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 70%;
}
.play-area {
  position: absolute;
  z-index: 1;
  bottom: 220px;
  left: 260px;
}
.play-area .play-on {
  width: 80px;
  height: 80px;
  color: #0071dc;
  background-color: #fff;
  text-align: center;
  font-size: 45px;
  line-height: 85px;
  border-radius: 50px;
  display: inline-block;
}
.play-area .play-on i {
  position: relative;
  left: 4px;
  top: 1px;
}
.build-item,
.case-study-item {
  position: relative;
  margin-bottom: 80px;
}
.build-item .content,
.case-study-item .content {
  position: absolute;
  left: 0;
  right: 0;
  width: 90%;
  bottom: -80px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px;
}
.build-item .content h3,
.case-study-item .content h3 {
  margin-bottom: 5px;
}
.build-item .content h3 a {
  color: #252525;
  transition: 0.5s;
}
.build-item .content ul li {
  display: inline-block;
  margin-right: 15px;
  color: #444;
  font-weight: 500;
  position: relative;
  font-size: 14px;
}
.blog-card .content ul li a,
.blog-item .content ul li a,
.build-item .content ul li a,
.case-study-item .content ul li a {
  color: #444;
}
.build-item .content ul li::before,
.case-study-item .content ul li::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -10px;
  width: 2px;
  height: 15px;
  background-color: #444;
  transform: skew(-20deg);
}
.build-item .content ul li:last-child,
.case-study-item .content ul li:last-child,
.coming-soon-area .coming-soon-content #timer div:first-child {
  margin-left: 0;
}
.build-item .content .more-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 20px;
  color: #0071dc;
  text-align: center;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 5px;
  background-color: #e6f1fc;
  transition: 0.5s;
}
.build-text {
  margin-bottom: 20px;
  margin-top: 30px;
}
.counter-content,
.play-btn-area {
  margin-bottom: 30px;
  text-align: center;
}
.build-text p {
  color: #fff;
  margin-bottom: 0;
  font-weight: 600;
}
.play-btn-area {
  position: relative;
  z-index: 1;
  padding-top: 170px;
  padding-bottom: 170px;
}
.case-play-btn::before,
.play-btn-area::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  border-radius: 15px;
}
.call-us-img,
.play-btn-area .build-play,
.talk-area,
.talk-area-two {
  z-index: 1;
  position: relative;
}
.play-btn-area .build-play {
  font-size: 35px;
  color: #fff;
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.5294117647);
  border-radius: 50%;
  line-height: 105px;
  text-align: center;
  transition: 0.7s;
  animation: 3s ease-out infinite pulse;
}
.case-play-btn .case-play i,
.play-btn-area .build-play i {
  position: relative;
  top: 1px;
  left: 3px;
}
.play-btn-area .build-play::before {
  content: "";
  position: absolute;
  width: 70px;
  height: 70px;
  left: 0;
  right: 0;
  top: 15px;
  margin: 0 auto;
  z-index: -1;
  background-color: #0071dc;
  border-radius: 50%;
}
.case-play-btn .case-play:hover,
.play-btn-area .build-play:hover {
  border-radius: 0;
  transform: scale(1);
}
.case-play-btn .case-play:hover::before,
.play-btn-area .build-play:hover::before {
  border-radius: 0;
  animation: none;
}
.talk-area {
  background-repeat: no-repeat;
}
.talk-area::before,
.technology-area::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #070b3b;
  opacity: 0.8;
}
.talk-content .section-title h2 {
  color: #fff;
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;
}

.talk-area-two::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #0071dc;
  opacity: 0.8;
}
.call-us-area::before,
.contact-info::before {
  content: "";
  position: absolute;
  opacity: 0.1;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
}
.call-us-img {
  text-align: center;
}
.call-shape .shape1 {
  position: absolute;
  top: 30px;
  left: 30px;
}
.call-shape .shape2 {
  position: absolute;
  top: 30px;
  right: -35px;
}
.call-contact {
  padding: 50px 0;
}
.call-contact h3 {
  font-size: 22px;
  color: #ffc221;
  margin-bottom: 5px;
}
.call-contact .call-btn {
  font-size: 35px;
  color: #fff;
  margin-bottom: 15px;
  font-weight: 600;
}
.call-contact p {
  font-size: 20px;
  color: #fff;
  margin-bottom: 25px;
}
.counter-area .section-title h2 {
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
}
.counter-area .section-title p {
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
}
.counter-content h1 {
  font-size: 170px;
  color: #252525;
  margin-bottom: 0;
  line-height: 1;
}
.counter-content h1 span {
  color: #0071dc;
  line-height: 1;
  position: relative;
  top: -6px;
}
.counter-content h3 {
  margin-bottom: 13px;
}
.counter-content p {
  max-width: 355px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
.counter-another-content {
  margin-bottom: 25px;
  padding-left: 90px;
  position: relative;
}
.counter-another-content i {
  position: absolute;
  line-height: 1;
  font-size: 60px;
  top: 3px;
  left: 0;
  color: #0071dc;
}
.counter-another-content h3 {
  color: #252525;
  font-size: 35px;
  line-height: 1;
  margin-bottom: 8px;
}
.counter-another-content span {
  font-size: 17px;
  color: #444;
  margin-bottom: 0;
  font-weight: 600;
}
.counter-shape .shape1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.counter-shape .shape1 img,
.counter-shape .shape2 img {
  max-width: 200px;
}
.counter-shape .shape2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.case-study-area .section-title h2 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.case-study-item .content ul li {
  display: inline-block;
  margin-right: 15px;
  color: #444;
  font-weight: 500;
  position: relative;
}
.case-study-item .content .more-btn,
.case-study-item2 .more-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 45px;
  height: 45px;
  line-height: 50px;
  font-size: 20px;
  color: #0071dc;
  text-align: center;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 5px;
  background-color: #e6f1fc;
  transition: 0.5s;
}
.case-play-btn,
.case-play-btn .case-play {
  z-index: 1;
  text-align: center;
  position: relative;
}
.clients-area .owl-nav .owl-next,
.clients-area .owl-nav .owl-prev {
  top: 50%;
  transform: translateY(-50%);
  width: 45px;
  height: 45px;
  line-height: 47px !important;
  background-color: transparent !important;
  color: #0071dc !important;
  font-size: 25px !important;
}
.case-article .case-article-content .case-article-list li i {
  font-size: 26px;
  color: #0071dc;
  position: absolute;
  left: 0;
  top: 2px;
  margin-right: 5px;
  transition: 0.5s;
}
.case-article .case-article-content .case-article-list.case-article-ls li i {
  top: -1px;
}
.case-play-btn {
  padding-top: 170px;
  padding-bottom: 170px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.case-play-btn .case-play {
  font-size: 40px;
  color: #0071dc;
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  line-height: 85px;
  transition: 0.7s;
  animation: 3s ease-out infinite pulse;
}
.about-img-5::before,
.clients-slider-img .clients-slider-circle {
  animation: 15s ease-in-out infinite border-transform;
  text-align: center;
}
.case-study-area-two::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60%;
  background-color: #070b3b;
}
.case-study-area-two .section-title h2 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}
.technology-area .section-title h2,
.technology-area-two .section-title h2 {
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
}
.blog-article .blog-article-img,
.team-card {
  margin-bottom: 30px;
  position: relative;
}
.team-card:hover .social-link {
  opacity: 1;
}
.team-card:hover .social-link li a {
  transform: scaleY(1);
}
.coming-soon-area .coming-soon-content .newsletter-form button::after,
.coming-soon-area .coming-soon-content .newsletter-form button::before,
.team-card:hover .content {
  border-radius: 0;
}
.team-card .social-link {
  position: absolute;
  top: 17%;
  right: 30px;
  padding: 0;
  list-style: none;
  opacity: 0;
}
.team-card .social-link li {
  display: block;
  margin-bottom: 10px;
}
.team-card .content h3,
.team-item .content h3,
.technology-card h3 {
  margin-bottom: 0;
  color: #fff;
}
.team-card .social-link li a {
  width: 30px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  border-radius: 50px;
  background-color: #0071dc;
  transform: scaleY(0);
}
.team-card .content {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0071dc;
  padding: 20px 25px;
  border-top-left-radius: 100px;
  text-align: center;
  transition: 0.9s;
}
.technology-area-three::before,
.technology-play-area::before {
  z-index: -1;
  width: 100%;
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  left: 0;
  content: "";
}
.technology-area {
  position: relative;
  z-index: 1;

  background-repeat: no-repeat;
}
.technology-area .section-title h2 {
  color: #fff;
}
.technology-card {
  padding: 30px;
  border: 1px solid #fff;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px;
}
.technology-card i {
  font-size: 50px;
  line-height: 1;
  color: #fff;
}
.technology-card h3 {
  margin-top: 15px;
  font-weight: 500;
}
.technology-card-color {
  border-color: #0071dc;
}
.technology-area-three .section-title h2 {
  color: #fff;
  max-width: 540px;
}
.technology-area-three .section-title p {
  color: #fff;
  margin-bottom: 20px;
  padding-top: 17px;
}
.technology-area-three::before {
  background-repeat: no-repeat;
  opacity: 0.4;
}
.technology-area-four .section-title p {
  padding-top: 18px;
}
.technology-item {
  padding: 17px 17px 15px;
  border: 1px solid #0071dc;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px;
}
.technology-item i {
  font-size: 35px;
  line-height: 1;
  color: #0071dc;
}
.technology-item h3 {
  color: #070b3b;
  margin-bottom: 0;
  margin-top: 17px;
  font-weight: 500;
  font-size: 18px;
}
.technology-play-area {
  padding: 200px 0;
  text-align: center;

  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-left: 20px;
}
.technology-play-area::before {
  background-color: #070b3b;
  opacity: 0.5;
  border-radius: 10px;
}
.technology-play-area .play-btn i {
  position: relative;
  z-index: 1;
  width: 90px;
  height: 90px;
  line-height: 90px;
  font-size: 40px;
  text-align: center;
  background-color: #fff;
  color: #ffc221;
  border-radius: 50px;
  transition: 0.7s;
}
.brand-area-two::before,
.brand-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.brand-area-two {
  position: relative;
  background-color: #0071dc;
}
.brand-item img {
  width: unset !important;
  text-align: center;
  margin: 0 auto;
}
.brand-item .brand-logo-two {
  display: none !important;
}
.clients-area,
.technology-area-five {
  position: relative;
  z-index: 1;
  background-color: #15163b;
}
.clients-area-two,
.clients-content,
.side-bar-widget {
  background-color: #fff;
}
.clients-area::before,
.technology-area-five::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;

  background-repeat: no-repeat;
}
.clients-area .section-title h2 {
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}
.clients-area .owl-nav .owl-prev {
  position: absolute;
  left: -7%;
  transition: 0.7s;
  border-radius: 50px;
  border: 1px solid #0071dc !important;
}
.clients-area .owl-nav .owl-next:hover,
.clients-area .owl-nav .owl-prev:hover,
.clients-area-three .owl-nav .owl-next:hover,
.clients-area-three .owl-nav .owl-prev:hover {
  color: #fff !important;
  background-color: #0071dc !important;
}
.clients-area .owl-nav .owl-next {
  position: absolute;
  right: -7%;
  transition: 0.7s;
  border-radius: 50px;
  border: 1px solid #0071dc !important;
}
.clients-area-two .section-title h2 {
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
  color: #252525;
}
.clients-content {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  padding: 30px 40px 25px;
  margin-bottom: 30px;
  border-radius: 10px;
}
.circle,
.clients-slider-content i {
  background-color: #0071dc;
  z-index: 1;
}
.clients-content::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 95%;
  height: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 0.5;
  border-radius: 10px;
}
.clients-content .content {
  padding-left: 110px;
  position: relative;
  margin-bottom: 30px;
}
.clients-content .content img {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 85px !important;
  height: 85px !important;
}
.clients-content .content i {
  position: absolute;
  bottom: -10px;
  left: 60px;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background-color: #ffc221;
  color: #fff;
  text-align: center;
}
.clients-content .content h3 {
  padding-top: 20px;
  margin-bottom: 0;
}
.client-circle .client-circle-1 {
  position: absolute;
  top: 10%;
  left: 0;
}
.client-circle .client-circle-2 {
  position: absolute;
  top: 40%;
  left: 3%;
}
.client-circle .client-circle-3 {
  position: absolute;
  top: 75%;
  left: 0;
}
.client-circle .client-circle-4 {
  position: absolute;
  top: 10%;
  right: 20%;
}
.client-circle .client-circle-5 {
  position: absolute;
  top: 25%;
  right: 5%;
}
.client-circle .client-circle-6 {
  position: absolute;
  top: 70%;
  right: 0;
}
.client-circle .client-circle-7 {
  position: absolute;
  bottom: 5%;
  right: 35%;
}
.circle {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  position: relative;
  border: 13px solid #f6fbff;
}
.clients-area-three .owl-nav .owl-next,
.clients-area-three .owl-nav .owl-prev {
  bottom: 90px;
  width: 40px;
  height: 40px;
  line-height: 42px !important;
  border-radius: 50px;
  background-color: transparent !important;
  color: #0071dc !important;
  position: absolute;
  transition: 0.7s;
}
.clients-area-three {
  background-color: #f4f4ff;
  position: relative;
  z-index: 1;
}
.blog-card,
.blog-item {
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  transition: 0.9s;
}
.clients-area-three::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;

  background-repeat: no-repeat;
  opacity: 0.7;
}
.clients-area-three .owl-nav {
  margin-top: 0;
  line-height: 0;
}
.clients-area-three .owl-nav .owl-prev {
  left: 575px;
  font-size: 25px !important;
  border: 1px solid #0071dc !important;
}
.clients-area-three .owl-nav .owl-next {
  left: 620px;
  font-size: 24px !important;
  border: 1px solid #0071dc !important;
}
.clients-slider-img {
  position: relative;
  z-index: 1;
  text-align: center;
}
.clients-slider-img img {
  width: unset !important;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.clients-slider-img .clients-slider-circle {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: -35px;
  right: 0;
  margin: 0 auto;
  width: 90%;
  height: 90%;
  background-image: linear-gradient(#0071dc, #070b3b);
}
.clients-slider-content {
  margin-bottom: 90px;
}
.clients-slider-content i {
  border-radius: 50px;
  width: 80px;
  height: 80px;
  line-height: 82px;
  font-size: 40px;
  color: #fff;
  text-align: center;
  display: inline-block;
  margin-bottom: 30px;
  position: relative;
}
.clients-slider-content i::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0.5px solid #0071dc;
  border-radius: 50px;
  animation: 2s ease-in-out infinite ripple;
}
.clients-slider-content p {
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 500;
}
.clients-slider-content h3 {
  margin-bottom: 7px;
  color: #0071dc;
}
.owl-item.active .clients-slider-item .clients-slider-img {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 0.7s;
}
.owl-item.active .clients-slider-item .clients-slider-content i {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 1.1s;
}
.owl-item.active .clients-slider-item .clients-slider-content p {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  animation-delay: 1.5s;
}
.owl-item.active .clients-slider-item .clients-slider-content h3 {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 1.7s;
}
.owl-item.active .clients-slider-item .clients-slider-content span {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  animation-delay: 1.9s;
}
.blog-card {
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
}
.blog-card .blog-img .blog-tag {
  background-color: #ffc221;
  padding: 15px 12px;
  position: absolute;
  bottom: -20px;
  right: 30px;
  border-radius: 5px;
  text-align: center;
}
.blog-article .blog-article-img .blog-article-tag h3,
.blog-card .blog-img .blog-tag h3,
.blog-style-card .blog-style-img .blog-style-tag h3 {
  font-size: 17px;
  color: #fff;
  line-height: 1;
  margin-bottom: 0;
  font-weight: 500;
}
.blog-card .blog-img .blog-tag span {
  color: #fff;
  line-height: 1;
  font-weight: 500;
}
.blog-card .content {
  padding: 25px 30px;
}
.blog-card .content ul li {
  display: inline-block;
  font-size: 15px;
  margin-right: 20px;
}
.blog-card .content ul li a i {
  font-size: 20px;
  color: #0071dc;
  margin-right: 5px;
  position: relative;
  top: 3px;
}
.blog-card .content h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 22px;
}
.blog-card .content p,
.blog-item .content p {
  margin-bottom: 8px;
}
.blog-card .content .read-btn {
  color: #0071dc;
  font-size: 15px;
  font-weight: 600;
}
.blog-card .content .read-btn i,
.blog-item .content .read-btn i {
  font-size: 20px;
  position: relative;
  top: 5px;
}
.blog-card .content .read-btn:hover,
.blog-item .content .read-btn:hover {
  color: #252525;
  letter-spacing: 0.25px;
}
.blog-item {
  margin-bottom: 30px;
  border-radius: 5px;
}
.blog-item .blog-img .blog-tag {
  background-color: #0071dc;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px;
  position: absolute;
  bottom: 30px;
  right: 30px;
  border-radius: 50px;
  text-align: center;
  display: inline-block;
  transition: 0.7s;
  color: #fff;
}
.blog-item .blog-img2 img {
  border-radius: 5px;
  padding: 20px;
  background-color: #e6f1fc;
}
.blog-item .blog-img2 .blog-tag,
.blog-item .blog-img3 .blog-tag {
  background-color: #0071dc;
  padding: 10px 14px 8px;
  position: absolute;
  top: 30px;
  right: 30px;
  text-align: center;
}
.blog-item .blog-img2 .blog-tag h3,
.blog-item .blog-img3 .blog-tag h3 {
  font-size: 20px;
  color: #fff;
  line-height: 1;
  margin-bottom: 2px;
  font-weight: 600;
}
.blog-item .blog-img2 .blog-tag span,
.blog-item .blog-img3 .blog-tag span {
  color: #fff;
  line-height: 1;
  font-weight: 400;
  font-size: 15px;
}
.blog-item .content {
  padding: 25px 21px;
}
.blog-item .content ul {
  list-style: none;
  margin: 0 0 10px;
  padding: 0;
}
.blog-item .content ul li {
  display: inline-block;
  font-size: 15px;
  margin-right: 20px;
  color: #444;
  font-weight: 500;
}
.blog-item .content ul li i {
  font-size: 18px;
  color: #0071dc;
  margin-right: 5px;
  position: relative;
  top: 3px;
  transition: 0.7s;
}
.blog-item .content ul li a i {
  color: #0071dc;
  margin-right: 5px;
  position: relative;
  font-size: 18px;
  transition: 0.7s;
}
.blog-item .content h3 {
  margin-bottom: 10px;
  font-size: 22px;
}
.blog-item .content .read-btn {
  color: #0071dc;
  font-size: 14px;
  font-weight: 600;
}
.blog-article .blog-article-img .blog-article-tag,
.blog-style-card .blog-style-img .blog-style-tag {
  background-color: #ffc221;
  padding: 15px 12px;
  position: absolute;
  bottom: -20px;
  right: 30px;
  border-radius: 3px;
  text-align: center;
}
.blog-article .blog-article-img .blog-article-tag span,
.blog-style-card .blog-style-img .blog-style-tag span {
  font-size: 17px;
  color: #fff;
  line-height: 1;
  font-weight: 500;
}
.blog-article .blog-article-title ul li,
.blog-style-card .content ul li {
  display: inline-block;
  font-size: 15px;
  color: #252525;
  margin-right: 30px;
  position: relative;
  font-weight: 500;
}
.blog-article .blog-article-title ul li i,
.blog-style-card .content ul li i {
  font-size: 18px;
  margin-right: 10px;
  position: relative;
  top: 3px;
  color: #0071dc;
}
.blog-article .blog-article-title h2 {
  margin-top: 15px;
  font-size: 26px;
  max-width: 650px;
  margin-bottom: 0;
  color: #252525;
}
.blog-article .article-content .blockquote {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  margin-top: 30px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  padding: 20px 30px 20px 50px;
  margin-left: 35px;
}
.blog-article .article-content .blockquote p {
  font-size: 15px;
  color: #444;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.blog-article .article-content .blockquote span {
  font-size: 15px;
  color: #252525;
  font-weight: 600;
}
.blog-article .article-content .blockquote i {
  font-size: 35px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  color: #fff;
  background-image: linear-gradient(to right, #070f60, #0071dc);
  text-align: center;
  border-radius: 50px;
  position: absolute;
  top: 24%;
  left: -35px;
}
.blog-article .blog-article-share {
  padding: 10px 0;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 30px;
}
.blog-article .blog-article-share .blog-tag ul li {
  display: inline-block;
  padding: 5px 3px;
  transition: 0.7s;
  color: #252525;
  font-weight: 400;
  margin-right: 5px;
}
.blog-article .blog-article-share .blog-tag ul li i {
  color: #0071dc;
  font-size: 18px;
  position: relative;
  top: 3px;
}
.blog-article .blog-article-share .blog-tag ul li a {
  color: #fff;
  background-color: #0071dc;
  padding: 5px 12px;
  border-radius: 10px;
  font-size: 14px;
}
.blog-article .blog-article-share .social-icon {
  margin: 0;
  padding: 0;
  list-style: none;
  float: right;
}
.blog-article .blog-article-share .social-icon li {
  display: inline-block;
  margin-right: 5px;
}
.blog-article .blog-article-share .social-icon li a {
  border-radius: 0;
  width: 35px;
  height: 35px;
  line-height: 37px;
  text-align: center;
  color: #0071dc;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.blog-article .comments-wrap .comment-title .title {
  font-size: 24px;
  margin-bottom: 0;
  color: #252525;
}
.blog-article .comments-wrap .comment-list li {
  position: relative;
  padding: 30px 30px 30px 75px;
  border-bottom: 1px solid #e1e1e1;
}
.blog-article .comments-wrap .comment-list li img {
  position: absolute;
  top: 30px;
  left: 0;
  border-radius: 50%;
}
.blog-article .comments-wrap .comment-list li h3 {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
  color: #252525;
}
.blog-article .comments-wrap .comment-list li span {
  font-size: 15px;
  color: #252525;
}
.blog-article .comments-wrap .comment-list li p {
  margin-top: 10px;
  margin-bottom: 0;
}
.blog-article .comments-wrap .comment-list li a {
  color: #0071dc;
  font-weight: 600;
  position: absolute;
  top: 30px;
  right: 0;
}
.blog-article .comments-form {
  margin-top: 30px;
  margin-bottom: 30px;
}
.blog-article .comments-form .title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #252525;
}
.blog-article .comments-form .contact-form {
  box-shadow: none;
  padding: 0;
  background-color: #fff;
}
.blog-article .comments-form .default-btn,
.user-form .contact-form .default-btn {
  width: unset;
}
.search-widget {
  margin-bottom: 30px;
  background-color: #fff;
}
.search-widget .search-form {
  position: relative;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
}
.search-widget .search-form .form-control {
  height: 50px;
  border: none;
  background-color: #fff;
  padding: 10px 20px;
  width: 100%;
  color: #252525;
  border-radius: 5px;
}
.search-widget .search-form button {
  position: absolute;
  top: 3px;
  right: 3px;
  height: 45px;
  width: 45px;
  transition: 0.5s;
  border: none;
  outline: 0;
  background-color: #0071dc;
  line-height: 57px;
  border-radius: 5px;
}
.search-widget .search-form button i {
  color: #fff;
  font-size: 24px;
}
.coming-soon-area .coming-soon-content ul li:hover,
.search-widget .search-form button:hover {
  background-color: #252525;
}
.side-bar-widget .title {
  font-size: 20px;
  color: #252525;
  padding: 0;
  font-weight: 600;
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  background-color: transparent;
  width: 100%;
}
.side-bar-widget .side-bar-categories ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.side-bar-widget .side-bar-categories ul li {
  position: relative;
  font-size: 15px;
  font-weight: 500;
}
.side-bar-widget .side-bar-categories ul li .line-circle {
  position: absolute;
  top: 10px;
  left: 0;
  width: 13px;
  height: 13px;
  background-color: transparent;
  border: 1px solid #0071dc;
  border-radius: 50px;
}
.side-bar-widget .side-bar-categories ul li .line-circle::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  margin: 0 auto;
  width: 9px;
  height: 9px;
  background-color: #0071dc;
  border-radius: 50px;
}
.side-bar-widget .side-bar-categories ul li a {
  display: inline-block;
  color: #444;
  padding: 5px 0 5px 25px;
  font-weight: 600;
  position: relative;
  width: 100%;
}
.side-bar-widget .side-bar-categories ul li a span {
  right: 30px;
  position: absolute;
  top: 10px;
  color: #444;
}
.side-bar-widget .widget-popular-post {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 20px;
}
.side-bar-widget .widget-popular-post .item {
  overflow: hidden;
  margin-bottom: 13px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e2e2e2;
}
.side-bar-widget .widget-popular-post .item:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.side-bar-widget .widget-popular-post .item .thumb {
  float: left;
  overflow: hidden;
  position: relative;
  margin-right: 15px;
}
.side-bar-widget .widget-popular-post .item .thumb .full-image {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
  position: relative;
  background-color: #070b3b;
}
.side-bar-widget .widget-popular-post .item .thumb .full-image.bg1 {
}
.side-bar-widget .widget-popular-post .item .thumb .full-image.bg2 {
}
.side-bar-widget .widget-popular-post .item .thumb .full-image.bg3 {
}
.side-bar-widget .widget-popular-post .item .thumb .full-image.bg4 {
}
.side-bar-widget .widget-popular-post .item .info {
  overflow: hidden;
  padding: 0;
}
.side-bar-widget .widget-popular-post .item .info .title-text {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 500;
  max-width: 210px;
}
.side-bar-widget .widget-popular-post .item .info .title-text a {
  display: inline-block;
  color: #252525;
  margin-bottom: 5px;
}
.side-bar-widget .widget-popular-post .item .info p {
  font-size: 15px;
  margin-bottom: 0;
  max-width: 180px;
  color: #0071dc;
}
.side-bar-widget .side-bar-widget-tag {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 20px;
}
.side-bar-widget .side-bar-widget-tag li {
  display: inline-block;
  margin: 5px;
  color: #fff;
}
.side-bar-widget .side-bar-widget-tag li a {
  color: #fff;
  padding: 7px 15px;
  background-color: #0071dc;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  transition: 0.7s;
}
.side-bar-widget .blog-gallery {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin: 10px -5px 25px;
}
.side-bar-widget .blog-gallery li {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}
.side-bar-widget .blog-gallery li a {
  display: block;
  position: relative;
}
.side-bar-widget .blog-gallery li a img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.side-bar-widget .blog-gallery li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0071dc;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  border-radius: 15px;
}
.side-bar-widget .blog-gallery li a::after {
  content: "\ee93";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0, -50%);
  text-align: center;
  background-color: transparent;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  font-size: 35px;
  font-family: boxicons;
  color: #fff;
}
.side-bar-widget .blog-gallery li a:hover::before {
  opacity: 0.5;
  visibility: visible;
}
.blog-style-card .content {
  padding: 30px 0 0;
}
.blog-style-card .content h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.blog-style-card .content h3 a {
  color: #252525;
  display: block;
}
.blog-style-card .content .default-btn {
  padding: 8px 16px;
  font-size: 14px;
}
.faq-area .section-title p {
  max-width: 560px;
}
.faq-content .section-title h2 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.2;
}
.faq-content .section-title p {
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
}
.faq-content .faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.faq-content .faq-accordion .accordion .accordion-item {
  display: block;
  margin-bottom: 15px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
}
.faq-content .faq-accordion .accordion .accordion-title {
  padding: 15px 60px 15px 20px;
  color: #252525;
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
}
.faq-content .faq-accordion .accordion .accordion-title i {
  position: absolute;
  right: 15px;
  top: 14px;
  transition: 0.5s;
  font-size: 24px;
  width: 30px;
  height: 30px;
  color: #0071dc;
  text-align: center;
  line-height: 30px;
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid #0071dc;
}
.faq-content .faq-accordion .accordion .accordion-title.active i::before {
  content: "\eb8b";
}
.faq-content
  .faq-accordion
  .accordion
  .accordion-title.active
  i
  .accordion-content
  p {
  margin-top: -5px;
}
.faq-content .faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  margin-top: 0;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
}
.faq-content .faq-accordion .accordion .accordion-content p {
  margin-top: -6px;
  line-height: 1.8;
  font-size: 15px;
  margin-bottom: 3px;
}
.user-form .contact-form {
  max-width: 640px;
}
.user-form .contact-form .agree-label .forget {
  position: absolute;
  right: 0;
}
.user-form .contact-form .account-desc {
  margin-top: 15px;
  font-weight: 600;
  margin-bottom: 0;
}
.pagination-area {
  margin-top: 10px;
  margin-bottom: 30px;
}
.pagination-area .page-numbers {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #0071dc;
  background-color: transparent;
  border: 1px solid #0071dc;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 18px;
  border-radius: 5px;
}
.coming-soon-area .coming-soon-content,
.contact-form {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.pagination-area .page-numbers i {
  position: relative;
  font-size: 25px;
  top: 5px;
}
.single-content h3 {
  font-size: 24px;
  color: #252525;
  margin-bottom: 15px;
}
.contact-form {
  max-width: 800px;
  z-index: 1;
  margin-bottom: 30px;
}
.contact-form h2 {
  color: #252525;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 26px;
  line-height: 1;
}
.contact-card h3,
.contact-card i {
  font-size: 24px;
  margin-bottom: 10px;
}
.contact-form .form-group {
  margin-bottom: 20px;
  position: relative;
}
.contact-form .form-group label {
  color: #252525;
  font-size: 15px;
  margin-bottom: 15px;
  font-weight: 500;
}
.contact-form .form-group.checkbox-option #chb2 {
  position: absolute;
  top: 6px;
  left: 0;
}
.contact-form .form-group.checkbox-option p {
  padding-left: 25px;
  font-size: 15px;
}
.contact-form .form-group .form-control {
  height: 50px;
  color: #252525;
  border: 1px solid #0071dc;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 20px;
  width: 100%;
  border-radius: 0;
  font-weight: 500;
}
.contact-form .form-group .form-control:focus {
  outline: 0;
  border-color: #252525;
  box-shadow: none;
}
.contact-form .form-group textarea.form-control {
  height: auto;
}
.contact-form .agree-label {
  margin-bottom: 15px;
  position: relative;
}
.contact-form .agree-label #chb1 {
  position: absolute;
  top: 5px;
  left: 0;
}
.contact-form .agree-label label {
  font-weight: 500;
  color: #252525;
  margin-left: 25px;
}
.contact-form .with-errors {
  float: left;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0;
  color: red;
  font-weight: 400;
  display: block;
}
.contact-form .text-danger {
  font-size: 18px;
  margin-top: 15px;
}
.contact-form .default-btn {
  border: 0;
  outline: 0;
}
.contact-form .form-group .form-control:-ms-input-placeholder,
.contact-form .form-group .form-control::-ms-input-placeholder,
.contact-form .form-group .form-control::-webkit-input-placeholder,
.contact-form .form-group .form-control::placeholder {
  color: #444;
}
.contact-card {
  text-align: center;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  padding: 30px 20px;
  border-radius: 15px;
}
.contact-card i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: #fff;
  color: #0071dc;
  border-radius: 50px;
  border: 1px solid #e2e2e2;
}
.contact-card h3 {
  color: #252525;
}
.contact-card span,
.contact-card span a {
  color: #444;
  display: block;
}
.contact-card span {
  font-weight: 500;
  font-size: 17px;
}
.contact-info {
  margin-bottom: 30px;
  background-color: #070b3b;
  padding: 52px 35px 22px;
  border-radius: 15px;
  position: relative;
  z-index: 1;
}
.contact-info span {
  color: #ffc221;
  display: block;
  margin-bottom: 5px;
}
.contact-info h2 {
  color: #fff;
  font-size: 26px;
  margin-bottom: 15px;
}
.contact-info p {
  color: #fff;
  margin-bottom: 20px;
}
.contact-info ul li {
  display: block;
  margin-bottom: 30px;
  padding-left: 60px;
  position: relative;
}
.contact-info ul li .content i {
  width: 45px;
  height: 45px;
  line-height: 45px;
  background-color: #fff;
  font-size: 20px;
  color: #0071dc;
  border-radius: 50px;
  border: 1px solid #e2e2e2;
  margin-bottom: 10px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}
.contact-info ul li .content h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #fff;
  display: block;
}
.contact-info ul li .content a {
  color: #fff;
  display: block;
  font-weight: 400;
  font-size: 15px;
}
.contact-info ul li .content span {
  color: #fff;
  font-weight: 400;
  display: block;
  font-size: 15px;
}
.error-area {
  height: 100%;
  padding-top: 50px;
  padding-bottom: 70px;
}
.error-area .error-content {
  text-align: center;
  position: relative;
}
.error-area .error-content h3 {
  margin-bottom: 20px;
  position: relative;
  color: #252525;
  font-size: 35px;
}
.error-area .error-content p {
  font-size: 18px;
  max-width: 520px;
  margin: 20px auto;
  color: #444;
}
.coming-soon-area {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
}
.coming-soon-area .coming-soon-content {
  text-align: center;
  max-width: 750px;
  z-index: 2;
  padding: 50px 30px;
  background-color: #f8f8f8;
  border: 3px solid #0071dc;
}
.coming-soon-area .coming-soon-content h1 {
  margin-bottom: 0;
  color: #252525;
  font-size: 50px;
}
.coming-soon-area .coming-soon-content p {
  font-size: 16px;
  max-width: 600px;
  margin: 15px auto 0;
  color: #444;
}
.coming-soon-area .coming-soon-content #timer {
  margin-top: 40px;
}
.coming-soon-area .coming-soon-content #timer div {
  display: inline-block;
  color: #252525;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 10px;
  font-size: 35px;
  font-weight: 700;
  background-color: #fff;
  border-radius: 5px;
}
.coming-soon-area .coming-soon-content #timer div span {
  display: block;
  text-transform: capitalize;
  margin-top: -15px;
  font-size: 16px;
  font-weight: 400;
  color: #252525;
}
.coming-soon-area .coming-soon-content #timer div::before {
  content: "";
  position: absolute;
  right: -50px;
  top: -10px;
  font-size: 70px;
  color: #fff;
}
.coming-soon-area .coming-soon-content .newsletter-form {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.coming-soon-area .coming-soon-content .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  height: 60px;
  border: 1px solid #ccc;
  background-color: #fff;
  padding-left: 15px;
  outline: 0;
  transition: 0.5s;
  border-radius: 0;
  color: #5d5d5d;
}
.coming-soon-area
  .coming-soon-content
  .newsletter-form
  .input-newsletter:focus {
  border-color: #252525;
}
.coming-soon-area .coming-soon-content .newsletter-form .default-btn {
  border: 0;
  outline: 0;
  border-radius: 0 !important;
}
.coming-soon-area .coming-soon-content .newsletter-form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 60px;
  padding: 0 30px;
  text-transform: uppercase;
  outline: 0;
  color: #fff;
  transition: 0.5s;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
.coming-soon-area .coming-soon-content .newsletter-form button:hover {
  color: #fff;
  background-color: #190f3c;
}
.coming-soon-area .coming-soon-content ul {
  list-style: none;
  margin-top: 30px;
  padding: 0;
}
.coming-soon-area .coming-soon-content ul li {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 50px;
  font-size: 18px;
  background-color: #0071dc;
  color: #fff;
  border-radius: 50px;
  margin-right: 10px;
}
.coming-soon-area #validator-newsletter {
  text-align: left;
  color: #dc3545 !important;
}
.map-area iframe {
  display: block;
  width: 100%;
  height: 400px;
}
.footer-widget .footer-logo {
  margin-bottom: 20px;
  position: relative;
  top: -5px;
}
.footer-widget h3 {
  margin-top: 0;
  font-size: 24px;
  margin-bottom: 30px;
  color: #fff;
  line-height: 1.2;
}
.footer-widget p {
  margin-bottom: 20px;
  color: #fff;
  max-width: 300px;
}
.footer-widget .footer-call-content {
  background-color: #fff;
  padding: 20px 80px 20px 20px;
  border-radius: 15px;
  position: relative;
  max-width: 345px;
}
.footer-widget .footer-call-content h3 {
  font-size: 24px;
  line-height: 1;
  margin-bottom: 5px;
  color: #252525;
}
.footer-widget .footer-call-content span a {
  color: #252525;
  font-weight: 600;
}
.footer-widget .footer-call-content i {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  font-size: 35px;
  line-height: 60px;
  text-align: center;
  display: inline-block;
  background-color: #e5f3ff;
  color: #0071dc;
  border-radius: 50px;
  transition: 0.7s;
}
.footer-widget .footer-list li {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}
.footer-widget .footer-list li a {
  color: #fff;
  font-weight: 400;
}
.footer-widget .footer-list li a i {
  font-size: 18px;
  position: relative;
  top: 3px;
  margin-right: 5px;
}
.footer-widget .footer-blog li {
  display: block;
  margin-bottom: 20px;
  padding-left: 90px;
  position: relative;
}
.footer-widget .footer-blog li a img {
  position: absolute;
  top: 0;
  left: 0;
  height: 70px;
  width: 70px;
}
.footer-widget .footer-blog li h3 {
  font-size: 16px;
  color: #fff;
  margin-bottom: 5px;
  max-width: 200px;
}
.footer-widget .footer-blog li h3 a {
  color: #fff;
  line-height: 1.4;
}
.footer-widget .footer-blog li span {
  font-size: 14px;
  color: #ffc221;
}
.footer-widget .newsletter-area .newsletter-form {
  position: relative;
  max-width: 270px;
  border-radius: 5px;
}
.footer-widget .newsletter-area .newsletter-form .form-control {
  background-color: #fff;
  height: 50px;
  line-height: 50px;
  margin: 0;
  border-radius: 5px;
  border: 0;
  padding: 0 45px 0 15px;
  max-width: 100%;
  color: #252525;
  font-weight: 400;
}
.footer-widget .newsletter-area .newsletter-form .form-control:focus {
  outline: 0;
  box-shadow: none;
  border: none;
}
.footer-widget .newsletter-area .newsletter-form .subscribe-btn {
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: #0071dc;
  color: #fff;
  height: 45px;
  line-height: 50px;
  width: 45px;
  border: 0;
  border-radius: 5px;
  font-size: 20px;
  transition: 0.5s;
}
.footer-widget .newsletter-area .newsletter-form .subscribe-btn:hover {
  background: #252525;
  color: #fff;
}
.footer-widget .newsletter-area .newsletter-form .validation-danger {
  font-size: 18px;
  margin-top: 5px;
  color: red;
}
.copy-right-area {
  padding: 15px 0;
  border-top: 1px solid rgba(0, 113, 220, 0.3803921569);
}
.copy-right-text {
  text-align: center;
}
.copy-right-text p {
  color: #fff;
  margin-bottom: 0;
}
.copy-right-text p a {
  color: #ffc221;
  border-bottom: 1px solid #ffc221;
}
.copy-right-text p a:hover {
  color: #0071dc;
  border-color: #0071dc;
}
.go-top {
  position: fixed;
  top: 60%;
  right: 3%;
  opacity: 0;
  cursor: pointer;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  background: #0071dc;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  line-height: 48px;
  z-index: 100;
  transition: 0.5s;
}
.buy-now-btn,
.buy-now-btn img {
  top: 50%;
  transform: translateY(-50%);
}
.go-top i {
  transition: 0.5s;
}
.go-top.active {
  top: 95%;
  transform: translateY(-95%);
  opacity: 1;
  visibility: visible;
}
.buy-now-btn {
  right: 20px;
  z-index: 99;
  position: fixed;
  border-radius: 30px;
  display: inline-block;
  color: #fff;
  background-color: #82b440;
  padding: 10px 20px 10px 42px;
  box-shadow: 0 1px 20px 1px #82b440;
  font-size: 15px;
  font-weight: 600;
}
.buy-now-btn img {
  left: 20px;
  width: 15px;
  position: absolute;
}
.buy-now-btn:hover {
  color: #fff;
  background-color: #94be5d;
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #070b3b;
}
.spinner,
.team-item:hover .content .social-link {
  width: 40px;
}
.banner-six,
.footer-bg2::before {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.spinner {
  height: 40px;
  border-radius: 5px;
  background-color: #fff;
  margin: 100px auto;
  animation: 1.2s ease-in-out infinite rotate-in;
}
.top-header-right .top-header-social-bg ul li {
  margin-right: 5px;
}
.top-header-right .top-header-social-bg ul li a {
  width: 30px;
  height: 30px;
  line-height: 34px;
  text-align: center;
  background-color: #fff;
  color: #0071dc;
  transition: 0.7s;
}
.banner-six {
  position: relative;
  z-index: 1;

  overflow: hidden;
}
.about-img-5::before,
.banner-six-shape,
.banner-six::before {
  z-index: -1;
  position: absolute;
  right: 0;
}
.banner-six::before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(7, 11, 59, 0.3803921569),
    #070b3b
  );
}
.banner-six-content {
  padding-top: 130px;
  padding-bottom: 120px;
  max-width: 560px;
  margin-left: auto;
}
.banner-six-content h1 {
  font-size: 55px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 20px;
}
.banner-six-shape {
  top: 50%;
  transform: translateY(-50%);
}
.banner-six-shape img {
  height: 620px;
}
.about-img-5 {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 30px;
}
.about-img-5::before {
  content: "";
  bottom: 0;
  left: -45px;
  margin: 0 auto;
  width: 110%;
  height: 70%;
  background-image: linear-gradient(#0071dc, #070b3b);
}
.services-card-color-bg2 {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
  padding: 15px 15px 20px;
  border-radius: 15px;
  background-color: #fff;
}
.technology-area-five .section-title h2 {
  color: #fff;
  max-width: 550px;
  margin: 0 auto;
}
.team-item {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 40px;
}
.team-item .content {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 90%;
  margin: 0 auto;
  right: 0;
  background-color: #0071dc;
  padding: 20px 25px;
  text-align: center;
  transition: 0.9s;
}
.team-item .content .social-link {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  list-style: none;
  width: 5px;
  background-color: #ffc221;
  transition: 0.7s;
}
.team-item .content .social-link li {
  display: block;
  margin-bottom: 0;
}
.team-item .content .social-link li a {
  width: 30px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  border-radius: 50px;
  transform: translateX(-40px);
  opacity: 0;
  transition: 0.7s;
}
.team-item:hover .content .social-link li a {
  transform: translateX(0);
  opacity: 1;
}
.case-study-item2 {
  position: relative;
  background-color: #fff;
  padding: 25px 25px 30px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}
.case-study-item2 .icon-services {
  color: #0071dc;
  font-size: 55px;
  margin-bottom: 15px;
  display: block;
  line-height: normal;
}
.case-study-item2 h3 {
  margin-bottom: 17px;
}
.footer-widget .footer-contact-list,
.footer-widget .social-link ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer-widget .social-link ul li {
  display: inline-block;
  top: 1px;
  position: relative;
  margin-right: 5px;
}
.footer-widget .social-link ul li a {
  width: 35px;
  height: 35px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
  color: #0071dc;
  transition: 0.7s;
  font-size: 18px;
}
.footer-widget .footer-contact-list li {
  display: block;
  margin-bottom: 15px;
  padding-left: 35px;
  position: relative;
}
.footer-widget .footer-contact-list li i {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24px;
  color: #ffc221;
  line-height: normal;
}
.footer-widget .footer-contact-list li .content a {
  color: #fff;
  transition: 0.7s;
}
.footer-bg2 {
  background-color: #070b3b;
  position: relative;
  z-index: 1;
}
.footer-bg2::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;

  opacity: 0.1;
}
@keyframes rotate-in {
  0% {
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
@keyframes moveBounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(50px);
  }
}
@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes border-transform {
  0%,
  100% {
    border-radius: 50%;
  }
  14% {
    border-radius: 50% 20% 50% 30%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
